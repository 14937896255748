import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we’ve processed ~500 million messages through our platform.`}</p>
    <p>{`One of the interesting aspects of conversational interfaces is the unstructured data users send into bots — everything from text to images, stickers, video, audio, links, location maps, and more.`}</p>
    <p>{`We took a deeper look at images and stickers sent to Facebook and Kik bots on our platform during the month of December.`}</p>
    <h2>{`Stick’em up!`}</h2>
    <p>{`Stickers are more than just an image — they’re communicating feelings and emotions.`}</p>
    <p>{`Nearly 44% of Facebook bots and 54% of Kik bots receive stickers from users.`}</p>
    <p>{`As you can see for both Facebook and Kik, emoticon stickers tend to be most popular… along with cats!`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132220/0_jTP_5xEByZuvh9oR-361x1024.png)
    </figure>
Users communicate in more ways than just text and voice. These stickers are
another way to communicate thoughts and feelings.
    <p>{`Analyzing the types of stickers provides an opportunity to better respond to users which can increase user satisfaction and the overall engagement in your bot.`}</p>
    <h2>{`The Gift of Gifs…`}</h2>
    <p>{`In a similar way to stickers, animated images are another way of communicating emotions and feelings.`}</p>
    <p>{`Nearly 14.5% of Facebook bots receive animated images.`}</p>
    <p>{`These are the most common animated images based on number of bots and number of messages respectively:`}</p>
    <h3><strong parentName="h3">{`Most Common Gifs (based on number of bots)`}</strong></h3>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132219/0_CU3yyCSNBkH8tLVw-300x182.gif)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132217/0_jRMS_UNeyYmDQL3s-300x169.gif)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132216/0_5XbZoO8C0GP19zQc-300x225.gif)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132215/0_JsoIhFVhmhVxUZO6-300x225.gif)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132214/0_esxoHBOz__UUmiLh.gif)
    </figure>
### **Most Common Gifs (based on number of messages)**
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/02/07132213/0_eqj0wvC21fpLvrlz-300x225.gif",
          "alt": null
        }}></img></figure><figure><img parentName="h2" {...{
          "src": "https://cdn-images-1.medium.com/max/800/0*n9oESo7zA4g7cuQa.",
          "alt": null
        }}></img></figure><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/02/07132212/0_qANEqTI_fyRFobKa-300x183.gif",
          "alt": null
        }}></img></figure><figure><img parentName="h2" {...{
          "src": "https://cdn-images-1.medium.com/max/800/0*lwJBsevf_tgD7Yqn.",
          "alt": null
        }}></img></figure><figure><img parentName="h2" {...{
          "src": "https://cdn-images-1.medium.com/max/800/0*dVnQZfQHSNTVVQ6I.",
          "alt": null
        }}></img></figure><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/02/07132211/b0fb5-1joaqvydweoei4oisctumxq.png",
          "alt": null
        }}></img></figure>{`Want to chat? Here’s my selfie…`}</h2>
    <p>{`In the case of images, about 37% of Facebook bots and 62% of Kik bots receive images.`}</p>
    <p>{`We ran the images from December through Amazon’s Rekognition API for image detection. The API outputs labels detected with the confidence level of detection. We filtered the labels further to look at labels with greater than 90% confidence level.`}</p>
    <p>{`The most common labels for images based on the number of bots receiving them are listed below.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132210/0_UIFYgxDIhAhn2v2f.png)
    </figure>
The most common type of image, whether by number of bot or number of messages
are images of people — often selfies — Person, Human, People, Portrait are
images of people and often skew to selfies, although keep in mind Selfie is a
label as well.
    <p>{`For further clarity, on the labels:`}</p>
    <ul>
      <li parentName="ul">{`Poster and Collage generally applied to collages of images, most often collages of people and selfies`}</li>
      <li parentName="ul">{`Flyer and Brochure include images with text`}</li>
      <li parentName="ul">{`Text included pictures with text, a decent amount of photos of receipts, sms/text screenshots, and other writings`}</li>
      <li parentName="ul">{`Paper is similar to Text, but skews more to photos of writing on paper`}</li>
      <li parentName="ul">{`Animal and Mammal contain all types of animals from pets to wildlife`}</li>
      <li parentName="ul">{`Trademark and Logo were similar — images of brand logos`}</li>
    </ul>
    <p>{`An interesting take away with Kik is the addition of Comics, Manga, and Drawing rated more highly than in Facebook.`}</p>
    <h2>{`Why are these important?`}</h2>
    <p>{`This is another opportunity to better understand your users. Why are they sending images and what’s the context of the images. These are opportunities to provide support for images sent in which can increase user engagement.`}</p>
    <p>{`We had a customer who didn’t realize the number of images sent to their bot and initially ignored them. Once they saw the amount, they started responding and acknowledging the images, creating a personality for the bot that resulted in an overall increase in user engagement.`}</p>
    <h2>{`Is there a Gender Bias?`}</h2>
    <p>{`In the case of Facebook, we can further drill into the labels based on gender. Here’s what we found:`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/02/07132209/0_S3OHvd2xPcEpAlYN.png)
    </figure>
In the case of images from female users, we see more Portrait, Collage, and Head
labels which generally are applied to selfie type photos. Even the Electronics
label is often triggered by the phone in the image when taking a selfie in a
mirror.
    <p>{`For images from male users, there are bit more labels around vehicles/cars and the addition of food and pets.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2017/02/07132209/1ffca-1kse2cgpmagqdejvpqqyqna.png",
          "alt": null
        }}></img></figure>{`My eyes, my eyes!`}</h2>
    <p>{`Lastly, we took a look at NSFW images — our poor eyes — these aren’t for the faint of heart.`}</p>
    <p>{`Thankfully, it turns out the relative amount of NSFW images is quite low — only about 2.5% of images to Facebook and 1.6% of images to Kik were NSFW and a relatively small percentage of bots receive them — i.e. 6% of Facebook bots. An even smaller number of users send NSFW users — in the case of Facebook it’s less than 1% (~0.9%) and with Kik it’s ~2.7%.`}</p>
    <p>{`In the case of Facebook, both male and female users send NSFW images, although it leans heavily to male users — of the NSFW images, ~90% were coming from males.`}</p>
    <p><strong parentName="p">{`The interesting thing is, while it’s a small number of users and images, the users send the images a lot!`}</strong></p>
    <p>{`When a user sends an NSFW image, they generally don’t send it only once. In fact, on Facebook, the same NSFW image is sent on average 4.75 times. Males will send the same NSFW image about 5.1 times whereas females will send the same image 2.8 times. There are even users who have sent the same NSFW image over 100 times!`}</p>
    <p>{`Apparently, once you’re over the threshold of sending an NSFW image, you’re ok sending the same image multiple times.`}</p>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we have bot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Slack, Google Assistant, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      